<template>
  <div class="SectionElement move">
    <transition name="fade" mode="out-in">
      <confirm
        v-if="confirmOpen"
        :title="'Are you sure you want to delete this element?'"
        :content="'This action is irreversible!'"
        @cancel="closeConfirm"
        @confirm="remove"
      />
    </transition>
    <div class="SectionElement__card">
      <img :src="element.iconUrl" class="SectionElement__icon" />
      <div class="SectionElement__card-text">
        <div class="SectionElement__title">{{ element.name }}</div>
        <div class="SectionElement__description">{{ element.description }}</div>
      </div>
      <div class="SectionElement__icons">
        <i class="icon icon-pencil icon-primary pointer" @click="edit" />
        <i
          class="icon icon-trash-empty icon-danger pointer"
          @click="openConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Confirm from '@/components/common/navigation/Confirm';

export default {
  name: 'SectionElement',
  components: {
    Confirm,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    elementindex: {
      type: Number,
      required: true,
    },
    sectionindex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      confirmOpen: false,
    };
  },

  methods: {
    openConfirm() {
      this.confirmOpen = true;
    },
    closeConfirm() {
      this.confirmOpen = false;
    },
    remove() {
      this.closeConfirm();
      this.$store.dispatch('removeElement', {
        section: this.sectionindex,
        element: this.elementindex,
      });
    },
    edit() {
      this.$router.push({
        name: 'editElement',
        params: {
          sectionIndex: this.sectionindex,
          elementIndex: this.elementindex,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/more-tab/sections/section/SectionElement.scss';
</style>
